import { useEffect, useState } from "react";
import { loader } from "react-global-loader";
import { Icon } from "@iconify/react";
import { Button } from "../../components/ui/button";
import Common from "../../helper/common";
import AdvanceTable from "../../components/advanceTable";
import { useNavigate } from "react-router-dom";

const ClubAccount = () => {
    const [ClubAccount, setClubAccount] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

    const GetClubAccounts = async () => {
    setLoading(true);
        const response = await Common.ApiService.getInstance().request("GetCubAccountList");
    let data = response?.data?.map((item) => ({
        ...item,
        isAcceptOnline: item.isAcceptOnline ? "Yes" : "No",
    }));
      setClubAccount(data);
    setLoading(false);
  };

  useEffect(() => {
    //loader.hide();
      GetClubAccounts();
  }, []);

    const _handleDelete = async (id) => {
        const isConfirmed = await Common.SweetAlert.confirmation(
            "Are you sure you want to delete this Club Account?",
            "Delete Club Account"
        );
        if (isConfirmed) {
            let resp = await Common.ApiService.getInstance().request(
                `DeleteClubAccount?id=${id}`,
                {},
                "Delete"
            );
            Common.SweetAlert.alert(resp.message, "success");
            setTimeout(() => {
                GetClubAccounts(1);
            }, 500);
        }
    };

    const _handleOnChange = async (obj) => {
        
        let resp = await Common.ApiService.getInstance().request(
            `ActiveDeactiveAccount?id=${obj.id}&isActive=${!obj.isActive}`,
            {},
            "POST"
        );

        Common.SweetAlert.alert(resp.message, "success");
        setTimeout(() => {

            setClubAccount(
                ClubAccount.map((item) =>
                    item.id == obj.id ? { ...item, isActive: !item.isActive } : item
                )
            );
            //GetClubAccounts(1);
        }, 300);
    };

  const _thead = [
      { title: "Id", type: "number", isVisible: false, key: "id" },
      { title: "Payment Type", type: "text", isVisible: true, key: "paymentTypeName" },
      { title: "Account Name", type: "text", isVisible: true, key: "name" },
      { title: "Bank Name", type: "text", isVisible: true, key: "bankName" },
      { title: "Payment Gateway", type: "text", isVisible: true, key: "paymentGatewayName" },
      { title: "Is Accept Online", type: "text", isVisible: true, key: "isAcceptOnline" },
      { title: "Is Active", type: "toggle", isVisible: true, key: "isActive", onChanged: (item) => _handleOnChange(item), },
  ];


  const actions = [
    {
            type: "edit",
            onClick: (val) => {
              global.log(val.id)
              navigate(`/app/addAccount?id=${val.id}`, {});
          },
          visibleProp: "canEdit",
          visibleValue: true
    },
    {
        type: "delete",
        onClick: (val) => _handleDelete(val.id),
        visibleProp: "canDelete",
        visibleValue: true
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  const _addUpdateTrigger = () => (
    <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
      <Icon icon="heroicons:pencil" className="h-4 w-4" />
    </Button>
  );


  const _addBtn = () => (
      <Button onClick={() => navigate("/app/addAccount")}>
      <span className="text-xl mr-1">
        <Icon icon="icon-park-outline:plus" />
      </span>
      Add Account
    </Button>
  );

  return (
    <>
      <AdvanceTable
        title={"Club Accounts"}
        columns={_thead.filter((item) => item.isVisible)}
              data={{ data: ClubAccount }}
        addButton={_addBtn}
        showPagination={false}
        showAddButton
        actionButtons={actions}
        updateTrigger={_addUpdateTrigger}
      />
    </>
  );
};

export default ClubAccount;
