import React, { createContext, useState, useContext } from "react";

// Create the Context
const MenuContext = createContext();

// Create the Provider Component
export const MenuProvider = ({ children }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  // Function to toggle the menu
  const toggleMenu = () => setIsMenuVisible((prev) => !prev);

  return (
    <MenuContext.Provider
      value={{ isMenuVisible, setIsMenuVisible, toggleMenu }}
    >
      {children}
    </MenuContext.Provider>
  );
};

// Custom hook for using the MenuContext
export const useMenu = () => {
  return useContext(MenuContext);
};
