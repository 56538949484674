// contexts/AuthContext.js
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import common from "../helper/common";
import { envIsLive } from "../constants/appConstant";
// import { useNavigate } from "react-router-dom";

const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  // const navigate = useNavigate();
  useLayoutEffect(() => {
    
    (async () => {
      global.log("location", window.location.href);

      let _user = common.getUser();
      global.user = _user;
      global.log("_user", _user);
      setUser(_user);
      if (window.location.href.includes("app") && !_user?.id) {
        window.location.replace("/Login");
        // navigate("/", {replace:true});
      }
    })();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
