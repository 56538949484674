import * as yup from "yup";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Button } from "../../../components/ui/button";
import Common from "../../../helper/common";
import { FormikInput } from "../../../components/formik-components";
import { respEnum } from "../../../constants/appConstant";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../components/ui/dialog";
import { useNavigate } from "react-router-dom";

const FormikInitialValues = {};

const formikValidation = yup.object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    phone: yup
        .string()
        .nullable()
        .matches(/^\d{3}-\d{7}$/, "Phone must be in the format 301-2345678")
        .required("Phone Number is required"),
    email: yup.string().nullable().email("Invalid email format").notRequired(),
});

const AddCustomer = ({ id, onClose, open, setOpen, bookingModal }) => {
    const formikRef = useRef();
    const dialogRef = useRef();
    const navigate = useNavigate();
    const [isFormikInitialized, setIsFormikInitialized] = useState(false);

    useEffect(() => {
        setIsFormikInitialized(open);
    }, [open]);

    const onSubmitAddCustomer = async (d, action) => {
        let data = {
            ...d,
            id,
        };
        let response = await Common.ApiService.getInstance().request(
            "AddUpdateClubPlayer",
            data,
            "POST"
        );
        action.setSubmitting(false);
        if (response.status == respEnum.Success) {
            onClose(response.data, d.phone);
            setOpen(false);
            if (!onClose) {
                navigate("/app/clubcustomers", { replace: true });
            }
            // setTimeout(() => {
            //     SweetAlert.alert(response.message);
            // }, 500)
        }
    };

    const getCustomerInfo = async () => {
        let response = await Common.ApiService.getInstance().request(
            `GetClubPlayerDetails?playerId=${id}`
        );
        if (response.status == respEnum.Success) {
            formikRef.current.setValues(response.data[0]);
        }
    };

    useEffect(() => {
        if (id) {
            getCustomerInfo();
        }
    }, [id]);

    return (
        <div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    {bookingModal ? (
                        <Button
                            size="sm"
                            variant="ghost"
                            className="group px-2 py-1 text-xs cus-sty-01"
                        >
                            <Icon icon="heroicons:plus-20-solid" className="h-5 w-5" />
                        </Button>
                    ) : (
                        <Button
                            size="sm"
                            variant="primary"
                                className="flex items-center  gap-2"
                        >
                            <Icon icon="heroicons:plus-20-solid" className="h-4 w-4" />
                            Add
                        </Button>
                    )}
                </DialogTrigger>

                <DialogContent ref={dialogRef}>
                    <DialogHeader>
                        <DialogTitle className="text-base font-medium ">
                            {!id ? "Add" : "Update"} Customer
                        </DialogTitle>
                    </DialogHeader>

                    <div className="text-sm text-default-500  space-y-4">
                        <Formik
                            innerRef={formikRef}
                            onSubmit={onSubmitAddCustomer}
                            initialValues={FormikInitialValues}
                            validationSchema={formikValidation}
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {(formikProps) => (
                                <div className="md:grid md:grid-cols-1 gap-2 space-y-6 md:space-y-0">
                                    <div className="col-span-1">
                                        <div className="md:grid md:grid-cols-1  gap-2  space-y-6 md:space-y-0">
                                            <div className="flex flex-col gap-2">
                                                <FormikInput
                                                    formikProps={formikProps}
                                                    name={"firstName"}
                                                    label={"First Name"}
                                                    placeholder={"FirstName"}
                                                    maxLength={30}
                                                />
                                            </div>
                                            <div className="flex flex-col gap-2">
                                                <FormikInput
                                                    formikProps={formikProps}
                                                    name={"lastName"}
                                                    label={"Last Name"}
                                                    placeholder={"LastName"}
                                                    maxLength={30}
                                                />
                                            </div>
                                            <div className="flex flex-col gap-2">
                                                <FormikInput
                                                    formikProps={formikProps}
                                                    name={"phone"}
                                                    label={"Phone"}
                                                    placeholder={"308-1234567"}
                                                    maxLength={30}
                                                    isPhoneNumber={true}
                                                />
                                            </div>

                                            <div className="flex flex-col gap-2">
                                                <FormikInput
                                                    formikProps={formikProps}
                                                    name={"email"}
                                                    label={"Email"}
                                                    placeholder={"customer@gmail.com"}
                                                    maxLength={25}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )}
                        </Formik>
                    </div>
                    <DialogFooter className="mt-8 gap-2">
                        <DialogClose asChild>
                            <Button type="button" variant="outline">
                                Close
                            </Button>
                        </DialogClose>

                        {isFormikInitialized && formikRef.current && (
                            <Button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikRef.current?.handleSubmit();
                                }}
                            >
                                {formikRef.current.isSubmitting
                                    ? "Saving..."
                                    : !id
                                        ? "Add"
                                        : "Update"}
                            </Button>
                        )}
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};



export default AddCustomer;



