import { Formik } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "../../../components/ui/button";
import {
  FormikInput,
  FormikSelect,
  FormikSearchSelect,
  FormikDate,
  FormikSearchSectionSelect,
} from "../../../components/formik-components";
import Common from "../../../helper/common";
import dayjs from "dayjs";

// import "../../../assets/nascent.css";

import {
  IntCourtPriceMin,
  IntMax,
  IntMin,
  PaymentStatusEnum,
  PaymentTypeEnum,
  ResultStatus,
  ToCSharpFormat,
  DateminFormat,
  respEnum,
  SlotStatusEnum,
} from "../../../constants/appConstant";

import { useNavigate } from "react-router-dom";
import AddCustomer from "../../clubCustomers/components/addCustomer";
import animationData from "../../../assets/json/lotties/noData.json";
import common from "../../../helper/common";
import { useQueryParams } from "../../../contexts/queryParamsContext";

const formValidations = yup.object({
  courtId: yup.object().required("Court is Requied"),
  playerId: yup.number().required("Customer is Required"),
  noofplayers: yup.number().required("Select # no of players"),
  courtcharges: yup
    .number()
    .required("Enter Court Price")
    .min(0, "Court price can't be negative")
    .max(IntMax),
  bookingDate: yup
    .date()
    .required("Booking Date is Requied")
    .min(
      dayjs().subtract(24, "hours"),
      "Past Date should be within the last 24 hours"
    ),
  //.min(Common.Utility.today, "Date should be greater than previous date "),
  //.min(new Date(2024, 10, 5), "Date should be greater than previous date "),
  paymentStatus: yup.date().required("Payment Status is Requied"),
  startDateTime: yup
    .string()
    .required("Start Time is required")
    .test(
      "is-valid-time",
      "Start Time format should be in 00, 30 or 23:59 format",
      (value) => Common.Utility.CheckTime(value)
    )
    .matches(
      /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
      "Invalid time format (HH:mm)"
    ),

  endDateTime: yup
    .string()
    .required("End time is required")
    .test(
      "is-valid-time",
      "End Time format should be in 00, 30 23:59 format",
      (value) => Common.Utility.CheckTime(value)
    )
    .matches(
      /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
      "Invalid time format (HH:mm)"
    ),
});

export default function AddBooking({ onClose, bookingValues = {} }) {
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [allCustomerList, setallCustomerList] = useState([]);
  const [courtList, setCourtList] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const animationRef = useRef();
  const lottieInstance = useRef(null);
  const { params, setParams } = useQueryParams();

  const formInitialValue = {
    bookingDate: Common.moment().format(DateminFormat),
    courtId: undefined,
    noofplayers: 4,
    ...bookingValues,
  };

  const getLottie = async () => {
    const lot = await import("lottie-web");

    // Destroy the existing animation if there is one
    if (lottieInstance.current) {
      lottieInstance.current.destroy();
    }

    // Create a new animation instance and store it in the ref
    lottieInstance.current = lot.default.loadAnimation({
      loop: true,
      autoplay: true,
      animationData: animationData,
      container: animationRef.current,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    });
  };

  const formikRef = useRef();
  useEffect(() => {
    getLottie();

    return () => {
      if (lottieInstance.current) {
        lottieInstance.current.destroy();
      }
    };
  }, [bookedSlots]);

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    getCustomers();
    getCourts();
    await Common.delay();
    getClubPaymentTypes();
  };

  const getCustomers = async () => {
    return new Promise(async (res) => {
      const response = await Common.ApiService.getInstance().request(
        "ClubPlayersddl",
        {},
        "Get",
        false
      );
      const customerData = response?.data ?? [];

      const updatedCustomerList = [
        { id: null, name: "Select Customer", phone: "" },
        ...customerData.map((customer) => ({
          id: customer.id,
          name: customer.name,
          phone: customer.phone,
        })),
      ];
      global.log(updatedCustomerList);
      setCustomerList(updatedCustomerList);
      setallCustomerList(updatedCustomerList);

      setTimeout(res, 1000);
    });
  };

  const getBookedSlots = async (courtId, date, duration = 60) => {
    if (!courtId || !date) return;

    let url = `CheckCourtAvailability?courtId=${courtId}&playDate=${date}&duration=${duration}`;

    const response = await Common.ApiService.getInstance().request(url);
    setBookedSlots(response?.data ?? []);
  };

  const getCourts = async () => {
    const response = await Common.ApiService.getInstance().request(
      "GetSportWiseCourtddl"
    );

    if (response?.status == respEnum.Success) {
      setCourtList(response?.data ?? []);
      setTimeout(() => {
        if (bookingValues?.courtId) {
          response?.data?.forEach((item) => {
            var court = item.options.find(
              (option) => option.value == bookingValues.courtId
            );
            if (court) {
              formikRef.current?.setFieldValue("courtId", court);
              handleCourtChange(court);
            }
          });
        }
      }, 500);
    }
  };

  const getClubPaymentTypes = async () => {
    const response = await Common.ApiService.getInstance().request(
      "GetClubPaymentTypes"
    );

    if (response?.status == respEnum.Success) {
      setPaymentTypeList(response?.data ?? []);
    }
  };

  const onSubmitData = async (d, action) => {
    let data = {
      ...d,
      courtId: d.courtId.value,
      playerId: +d.playerId,
      noofplayers: +d.noofplayers,
      clubAccountId: +d.clubAccountId?.value,
      paymentStatus: +d.paymentStatus,
      courtcharges: +d.courtcharges,
      total: +d.courtcharges,
      startDateTime: Common.moment(
        `${d.bookingDate} ${d.startDateTime}`
      ).format(ToCSharpFormat),
      endDateTime: Common.moment(`${d.bookingDate} ${d.endDateTime}`).format(
        ToCSharpFormat
      ),
    };

    if (data.startDateTime >= data.endDateTime) {
      toast.error("End time must be greater");
      return;
    }

    // Validation check
    if (
      data.startDateTime < dayjs().subtract(24, "hours").toDate() ||
      data.endDateTime < dayjs().subtract(24, "hours").toDate()
    ) {
      toast.error("Start Time and End time should be within the last 24 hours");
      return;
    }

    // const tenDaysAgo = new Date();
    // tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);

    // if (data.startDateTime < tenDaysAgo || data.endDateTime < tenDaysAgo) {
    //     toast.error("Start Time and End Time should not be earlier than 10 days ago.");
    //     return;
    // }

    if (data.paymentStatus == PaymentStatusEnum.Paid && !data.clubAccountId) {
      toast.error("Please Provide the Payment Account");
      return;
    }

    if (data.paymentStatus == PaymentStatusEnum.Paid) {
      data.total = +d.courtcharges;
      data.receivedAmount = +data.total;
      data.remainingAmount = data.total - data.receivedAmount;
    } else if (data.paymentStatus == PaymentStatusEnum.Un_Paid) {
      data.clubAccountId = null;
    }

    let response = await Common.ApiService.getInstance().request(
      "AddClubBooking",
      data,
      "POST"
    );
    action.setSubmitting(false);
    if (response.status == ResultStatus.Success) {
      Common.showToast("Booking Created successfully!", "Success", "success");
      onClose(true);
    }
  };

  const _onCustomerAdded = useCallback(async (id, phone) => {
    formikRef.current.setFieldValue("phone", phone);
    setIsDropdownDisabled(false);
    formikRef.current.setFieldValue("playerId", id);
    await getCustomers();
  }, []);

  const _addCustomer = useCallback(() => (
    <AddCustomer
      id={undefined}
      onClose={_onCustomerAdded}
      open={open}
      setOpen={setOpen}
      bookingModal={true}
    />
  ));

  const checkPricing = () => {
    global.log("formik state updated");
    if (
      formikRef.current?.values.courtId &&
      formikRef.current?.values.startDateTime &&
      formikRef.current?.values.endDateTime
    ) {
      setTimeout(handleCourtCharges, 500);
    }
  };

  const handleCourtCharges = () => {
    const { courtId, startDateTime, endDateTime } = formikRef.current.values;
    global.log("courtId", courtId);
    global.log("startDateTime", startDateTime);
    global.log("endDateTime", endDateTime);
    // Extract duration formats from the selected court
    let durations =
      courtId?.formats?.map((item) => ({
        id: item.value,
        name: item.label,
        charges: item.charges,
        duration: item.value,
      })) ?? [];
    global.log("durations", durations);

    // Calculate duration difference in minutes
    const startTime = dayjs(`2024-01-01 ${startDateTime}`);
    const endTime = dayjs(`2024-01-01 ${endDateTime}`);
    let diffInMinutes = endTime.diff(startTime, "minute");
    const durationInMinutes =
      diffInMinutes % 30 == 29 ? diffInMinutes + 1 : diffInMinutes;
    global.log("durationInMinutes", durationInMinutes);

    // Find the matching duration and its charges
    const matchingDuration = durations.find(
      (duration) => duration.id === durationInMinutes
    );

    if (matchingDuration) {
      // Set court charges based on the matching duration
      formikRef.current.setFieldValue("courtcharges", matchingDuration.charges);
    } else {
      let chargesObj =
        durations.find((item) => item.duration == 60) ?? durations[0];
      let perMinuteCharges = chargesObj.charges / chargesObj.duration;
      formikRef.current.setFieldValue(
        "courtcharges",
        Math.trunc(perMinuteCharges * durationInMinutes)
      );
    }
  };

  const handleCourtChange = (selectedCourt) => {
    if (!selectedCourt || !formikRef.current) return;
    checkPricing();

    let durations =
      selectedCourt?.formats?.map((item) => ({
        id: item.value,
        name: item.label,
      })) ?? [];
    setDurationList(durations);

    getBookedSlots(
      selectedCourt.value,
      formikRef.current?.values.bookingDate,
      formikRef.current?.values.duration
    );
  };

  const handleSlotClick = (slot) => {
    if (slot.status == SlotStatusEnum.Booked) {
      setParams({
        ...params,
        bookingId: slot.bookingId,
      });
      navigate(`/app/bookingDetails`);
    } else if (slot.status == SlotStatusEnum.Available) {
      const formattedStartTime = Common.Utility.formatTimeToHHMM(slot.start);
      const formattedEndTime = Common.Utility.formatTimeToHHMM(slot.end);

      formikRef.current.setFieldValue("startDateTime", formattedStartTime);
      formikRef.current.setFieldValue("endDateTime", formattedEndTime);
      checkPricing();
    }
  };

  const handlePhoneBlur = async (phone, formikProps) => {
    if (!phone) {
      setCustomerList(allCustomerList);
      setIsDropdownDisabled(false);
      return;
    }
    return new Promise(async (res) => {
      const response = await Common.ApiService.getInstance().request(
        `GetAppCustomer?phone=${phone}`
      );

      if (response?.status == respEnum.Success && response.data) {
        const customerList = { id: response.data.id, name: response.data.name };
        setCustomerList([customerList]);
        formikRef.current.setFieldValue("playerId", response.data.id);
        setIsDropdownDisabled(true);
      } else {
        setCustomerList(allCustomerList);
        formikRef.current.setFieldValue("playerId", null);
        setIsDropdownDisabled(false);
      }
      setTimeout(res, 1000);
    });
  };

  return (
    <Formik
      initialValues={formInitialValue}
      validationSchema={formValidations}
      innerRef={formikRef}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitData}
    >
      {(formikProps) => (
        <div className="md:grid md:grid-cols-3 mb-10 gap-2 space-y-3 md:space-y-0">
          <div className="col-span-1">
            <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
              <div className="md:grid">
                <div className="md:grid md:grid-cols-2  gap-6 space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-2">
                    <FormikSearchSelect
                      formikProps={formikProps}
                      name={"playerId"}
                      data={customerList}
                      disabled={isDropdownDisabled}
                      placeholder={"Select"}
                      label={"Customer"}
                      labelButton={_addCustomer}
                      onChange={(e) => {
                        getBookedSlots(
                          formikProps.values.courtId?.value,
                          formikProps.values.bookingDate
                        );
                        const selectedCustomer = customerList.find(
                          (customer) => customer.id == parseInt(e, 10)
                        );
                        if (selectedCustomer) {
                          formikProps.setFieldValue(
                            "phone",
                            selectedCustomer.phone
                          );
                        } else {
                          formikProps.setFieldValue("phone", "");
                        }
                      }}
                    />
                  </div>

                  <div className="flex flex-col  sm-hidden gap-2">
                    <FormikInput
                      formikProps={formikProps}
                      name={"phone"}
                      label={"App Customer Phone #"}
                      placeholder={"308-1234567"}
                      maxLength={30}
                      isPhoneNumber={true}
                      onBlur={(e) =>
                        handlePhoneBlur(e.target.value, formikProps)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handlePhoneBlur(e.target.value, formikProps);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="md:grid">
                <div className="md:grid md:grid-cols-2  gap-6  space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-3">
                    <FormikSearchSectionSelect
                      formikProps={formikProps}
                      name={"courtId"}
                      label={"Court"}
                      data={courtList}
                      onChange={(val) => handleCourtChange(val)}
                    />
                  </div>
                  <div className="flex flex-col sm-hidden gap-3">
                    <FormikSelect
                      formikProps={formikProps}
                      name={"duration"}
                      label={"Duration"}
                      data={durationList}
                      onChange={(val) => {
                        getBookedSlots(
                          formikProps.values.courtId?.value,
                          formikProps.values.bookingDate,
                          val
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <FormikDate
                formikProps={formikProps}
                name={"bookingDate"}
                type={"date"}
                label={"Booking Date"}
                onChange={(val) => {
                  //Common.Utility.checkPreviousDate(val, "Booking Date");
                  getBookedSlots(
                    formikProps.values.courtId?.value,
                    val,
                    formikProps.values.duration
                  );
                }}
              />

              <div className="grid">
                <div className="grid grid-cols-2  gap-6  md:space-y-0">
                  <div className="flex flex-col gap-3">
                    <FormikInput
                      formikProps={formikProps}
                      name={"startDateTime"}
                      placeholder={"Start Time"}
                      label={"Start Time"}
                      type={"time"}
                      onBlur={(val) =>
                        Common.Utility.handleBlur(val, "startDateTime", true)
                      }
                      onChange={checkPricing}
                    />
                  </div>

                  <div className="flex flex-col gap-3">
                    <FormikInput
                      formikProps={formikProps}
                      name={"endDateTime"}
                      placeholder={"End Time"}
                      label={"End Time"}
                      type={"time"}
                      onBlur={(val) =>
                        Common.Utility.handleBlur(val, "startDateTime", true)
                      }
                      onChange={checkPricing}
                    />
                  </div>
                </div>
              </div>

              <div className="md:grid">
                <div className="md:grid md:grid-cols-2  gap-6 space-y-3 md:space-y-0">
                  <div className="flex flex-col  sm-hidden gap-3">
                    <FormikSelect
                      formikProps={formikProps}
                      name={"noofplayers"}
                      placeholder={"No of Players"}
                      label={"No of Player"}
                      data={[
                        { id: 2, name: 2 },
                        { id: 4, name: 4 },
                      ]}
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <FormikInput
                      formikProps={formikProps}
                      name={"courtcharges"}
                      placeholder={"Court Charges"}
                      label={"Court Charges"}
                      type={"number"}
                      max={IntMax}
                      min={IntCourtPriceMin}
                      onChange={(value) => {
                        if (value < IntMin && value != "") {
                          formikProps.setFieldValue("courtcharges", IntMin);
                        } else if (value > IntMax) {
                          toast.error(
                            `Court charges cannot be greater than ${IntMax}`
                          );
                          formikProps.setFieldValue("courtcharges", "");
                        } else {
                          formikProps.setFieldValue("courtcharges", value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="grid">
                <div className="grid grid-cols-2  gap-6 space-y-0">
                  <div className="flex flex-col gap-3">
                    <FormikSelect
                      formikProps={formikProps}
                      name={"paymentStatus"}
                      placeholder={"Payment Status"}
                      label={"Payment Status"}
                      // data={Common.Utility.enumToArray(PaymentStatusEnum)}
                      data={[
                        { id: PaymentStatusEnum.Paid, name: "Paid" },
                        { id: PaymentStatusEnum.Un_Paid, name: "Un Paid" },
                      ]}
                      onChange={(val) => {
                        formikProps.setFieldValue("paymentStatus", val);

                        if (val === PaymentStatusEnum.Paid) {
                          formikProps.setFieldValue(
                            "clubAccountId",
                            formikProps.values.clubAccountId
                          );
                        } else {
                          formikProps.setFieldValue("clubAccountId", null);
                        }
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    {/* <FormikSelect
                      formikProps={formikProps}
                      name={"paymentType"}
                      placeholder={"Payment Type"}
                      label={"Payment Type"}
                      data={Common.Utility.enumToArray(PaymentTypeEnum)}
                      disabled={
                        formikProps.values.paymentStatus !==
                        PaymentStatusEnum.Paid
                      }
                    /> */}

                    <FormikSearchSectionSelect
                      formikProps={formikProps}
                      name={"clubAccountId"}
                      label={"Payment Account"}
                      data={paymentTypeList}
                      disabled={
                        formikProps.values.paymentStatus !==
                        PaymentStatusEnum.Paid
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <Button
                  className="mt-5"
                  onClick={(e) => {
                    e.preventDefault();
                    formikProps.handleSubmit();
                  }}
                  disabled={formikProps.isSubmitting}
                  type="button"
                >
                  {" "}
                  {formikProps.isSubmitting ? "Saving..." : "Add Booking"}{" "}
                </Button>

                <Button
                  color="secondary"
                  className="mt-5 ml-5 "
                  onClick={onClose}
                  type="button"
                >
                  Close
                </Button>
              </div>
            </div>
          </div>

          {/* No Slots Data */}
          {bookedSlots.length <= 0 && (
            <div className="col-span-2">
              <div className="px-0 md:px-3  py-6">
                <div className="grid grid-cols-1 gap-2 flex justify-center items-center">
                  <div className="no-data-icon-sty" ref={animationRef}></div>
                  <h5 className="text-md text-bold text-center mb-4">
                    Please select a court for available time slots.
                  </h5>
                </div>
              </div>
            </div>
          )}
          {/* No Slots Data End */}

          {bookedSlots.length > 0 && (
            <div className="col-span-2">
              <div className="px-3 py-6">
                <div className="grid  grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2">
                  {bookedSlots.map((bs, index) => (
                    <Button
                      key={index}
                      color={
                        bs.status == SlotStatusEnum.Available
                          ? "success"
                          : "destructive"
                      }
                      variant="soft"
                      className="rounded text-xs"
                      onClick={() => handleSlotClick(bs)}
                    >
                      {Common.Utility.formatTimeToAMPM(bs.start)} -{" "}
                      {Common.Utility.formatTimeToAMPM(bs.end)}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Formik>
  );
}
