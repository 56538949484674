import SidebarImage from "./sidebar-image";
import SelectLayout from "./select-layout";
import SelectTheme from "./select-theme";
import HeaderStyle from "./header-style";
import FooterStyle from "./footer-style";
import { Button } from "../../ui/button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../ui/sheet";
import { ScrollArea } from "../../ui/scroll-area";
// import { Settings } from "../../../svg";
import CustomIcon from "../../CustomIcon";

const ThemeCustomizer = ({
  trigger = (
    <div className="fixed right-4 bottom-14 z-50">
      <Button size="icon" className=" relative h-12 w-12  rounded-full ">
        <CustomIcon className="h-7 w-7 animate-spin" />
      </Button>
    </div>
  ),
}) => {
  return (
    <Sheet>
      <SheetTrigger asChild>{trigger}</SheetTrigger>
      <SheetContent
        overlayClass=" bg-transparent backdrop-blur-none"
        className="lg:w-3/4 w-full max-w-full md:max-w-sm px-6 pt-0 pb-6"
      >
        <SheetHeader className=" text-start border-b -mx-6 px-6 py-4 shadow-sm md:shadow-none">
          <SheetTitle className=" text-base  font-medium ">
            Theme Customizer
          </SheetTitle>
        </SheetHeader>
        <ScrollArea className="h-[calc(100%-120px)] -mx-6 px-6">
          <div className=" space-y-8 mt-3">
            <SelectLayout />

            <SelectTheme />
            {/*<ThemeChange />
            <SidebarChange />
            <RadiusInit />
            */}

            <SidebarImage />
            <HeaderStyle />
            <FooterStyle />
          </div>
        </ScrollArea>
        {/* <SheetFooter className="py-4 gap-3 lg:flex justify-between hidden  ">
          <Button asChild className="w-full">
            <Link href="/dashboard">Buy Now</Link>
          </Button>
          <Button asChild className="w-full">
            <Link href="/dashboard">Our Portfolio</Link>
          </Button>
        </SheetFooter> */}
      </SheetContent>
    </Sheet>
  );
};

const ThemeCustomize = () => (
  <div className=" mobile-menu-btn bottom-[-2px] z-50  fixed   left-1/2 transform -translate-x-1/2   ">
    <Button size="icon" className="   relative h-[35px] w-[35px] rounded-full ">
      <CustomIcon className="h-[40px] w-[40px]    animate-spin" />
    </Button>
  </div>
);

export default ThemeCustomize;
