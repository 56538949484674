import React, { useState, useCallback, useRef, useEffect } from "react";
import { GoogleMap, Marker, useLoadScript, Autocomplete } from "@react-google-maps/api";

// Move libraries outside of the component
const libraries = ['places'];

const GoogleMapCustom = ({ defaultLat, defaultLng, onLocationChange, isReinit }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyAE6MQgx8MwLZCVOp7Qb11j4zG6hd9YikM",
        libraries, // Use the static libraries array
    });

    const [markerPosition, setMarkerPosition] = useState({
        lat: defaultLat,
        lng: defaultLng,
        pinLocationName: ""
    });
    const [mapCenter, setMapCenter] = useState({ lat: defaultLat, lng: defaultLng });
    const autocompleteRef = useRef(null);

    useEffect(() => {
        if (onLocationChange) {
            onLocationChange(markerPosition);
        }
    }, [markerPosition, onLocationChange]);

    const handleMarkerDragEnd = useCallback((event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();

        // Reverse geocoding to get location name
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
            { location: { lat: newLat, lng: newLng } },
            (results, status) => {
                if (status === "OK") {
                    if (results[0]) {
                        const newPosition = {
                            lat: newLat,
                            lng: newLng,
                            pinLocationName: results[0].formatted_address
                        };
                        setMarkerPosition(newPosition);

                        // Ensure onLocationChange is called with the new position
                        if (onLocationChange) {
                            onLocationChange(newPosition);
                        }
                    }
                }
            }
        );
    }, [onLocationChange]);

    const handlePlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        if (place.geometry) {
            const newCenter = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                pinLocationName: place.formatted_address || place.name
            };
            setMapCenter(newCenter);
            setMarkerPosition(newCenter);

            // Call onLocationChange with the new location details
            if (onLocationChange) {
                onLocationChange(newCenter);
            }
        }
    };

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Autocomplete
                onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                onPlaceChanged={handlePlaceChanged}
            >
                <input
                    type="text"
                    placeholder="Search for a location"
                    style={{
                        width: "100%",
                        padding: "10px",
                        marginBottom: "10px",
                        fontSize: "16px",
                        border: "1px solid #ccc",
                        borderRadius: "6px",
                    }}
                />
            </Autocomplete>

            <GoogleMap
                mapContainerStyle={{
                    width: "100%",
                    height: "400px",
                }}
                center={mapCenter}
                zoom={14}
            >
                <Marker
                    position={markerPosition}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                />
            </GoogleMap>

            {/* <p className="mt-4">
                <strong>Selected Coordinates:</strong> Latitude: {markerPosition.lat}, Longitude: {markerPosition.lng}
                <br />
                <strong>Location Name:</strong> {markerPosition.pinLocationName}
            </p> */}
        </div>
    );
};

export default GoogleMapCustom;