import { useEffect, useState } from "react";
import Card from "../ui/card-snippet";
import { CardContent } from "../ui/card";
import { AppCheckBox } from "../appComponents";

const FacilitiesCard = ({ name, formikProps, title, data = [] }) => {
    const [facilities, setfacilities] = useState([]);

  useEffect(() => {
    setfacilities(data);
  }, [data]);

  useEffect(() => {
    formikProps.setFieldValue(
      name,
      facilities.filter((item) => item.isSelected)
    );
  }, [facilities]);

  return formikProps ? (
    <>
      <div className="flex flex-col gap-2">
        <Card title={title}>
          <CardContent className="p-4">
            <div className="space-y-3">
              {facilities.map((item) => (
                <AppCheckBox
                  type="checkbox"
                  value={item.isSelected}
                  onChange={(val) =>
                    setfacilities(
                      facilities.map((fac) =>
                          fac.id == item.id
                          ? {
                              ...item,
                              isSelected: val,
                              facilityId: item.id,
                            }
                              : fac
                      )
                    )
                  }
                  className="border-default-300"
                  text={item.name}
                />
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  ) : null;
};

export default FacilitiesCard;
