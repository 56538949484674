import ErrorText from "./error-text";
import { AppSearchSectionSelect } from "../appComponents";
import { useMemo } from "react";

export default function FormikSearchSectionSelect({
  formikProps,
  onChange = () => {}, // Default empty function
  name,
  placeholder,
  data = [],
  label,
  labelButton,
  style,
  className,
  removeWrapper = false,
  disabled = false,
}) {
  // const defaultValue = useMemo(
  //     () =>
  //         formikProps.values[name]
  //             ? dataList.find((item) => item.value == formikProps.values[name])
  //             : undefined,
  //     [data, formikProps.values[name], name]
  // );

  const dataList = useMemo(() => {
    return data != null && data.length
      ? data?.map((item) => item.options).flat()
      : [];
  }, [data]);

  // Custom onChange handler to update Formik state and call the passed onChange function
  const handleChange = (selectedOption) => {
    if (disabled) return;
    const value = selectedOption;
    formikProps.setFieldValue(name, value);
    onChange(value); // Call the onChange prop with the selected value
  };

  const getValue = () => {
    if (formikProps.values[name]) {
      dataList.find((item) => item.value == formikProps.values[name]);
    }
    return null;
  };

  return (
    <div>
      <AppSearchSectionSelect
        value={formikProps.values[name]}
        data={data}
        label={label}
        labelButton={labelButton}
        onChange={handleChange}
        disabled={disabled}
      />
      <ErrorText name={name} formikProps={formikProps} />
    </div>
  );
}
