import line from "../assets/images/auth/line.png";
import shortImage from "../assets/images/all-img/short-image.png";
import shortImage2 from "../assets/images/all-img/short-image-2.png";
import menuImage1 from "../assets/images/all-img/img-1.jpeg";
import menuImage2 from "../assets/images/all-img/img-2.jpeg";
import manWithLaptop from "../assets/images/all-img/man-with-laptop.png";
import flag1 from "../assets/images/all-img/flag-1.png";
import flag2 from "../assets/images/all-img/flag-2.png";
import flag3 from "../assets/images/all-img/flag-2.png";
import flag4 from "../assets/images/all-img/flag-4.png";
import avatar1 from "../assets/images/avatar/avatar-1.jpg";
import avatar2 from "../assets/images/avatar/avatar-2.jpg";
import avatar3 from "../assets/images/avatar/avatar-3.jpg";
import avatar4 from "../assets/images/avatar/avatar-4.jpg";
import avatar5 from "../assets/images/avatar/avatar-5.jpg";
import avatar6 from "../assets/images/avatar/avatar-6.jpg";
import avatar7 from "../assets/images/avatar/avatar-7.jpg";
import avatar8 from "../assets/images/avatar/avatar-8.jpg";
import banana from "../assets/images/all-img/banana.jpg";
import headphone from "../assets/images/all-img/headphone.png";
import baby from "../assets/images/all-img/baby.jpg";
import busket from "../assets/images/all-img/busket.jpg";
import mic from "../assets/images/all-img/mic.jpg";
import orange from "../assets/images/all-img/orange.jpg";
import location from "../assets/images/all-img/location.png";

export const ImageConstant = {
  location,
  banana,
  headphone,
  baby,
  busket,
  mic,
  orange,
  line,
  shortImage,
  menuImage1,
  menuImage2,
  manWithLaptop,
  flag1,
  flag2,
  flag3,
  flag4,
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  shortImage2,
};
