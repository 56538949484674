import ReportChart from "./components/report-chart";
import ReportsCard from "./components/reports";
import WelcomeBlock from "./components/welcome-block";
import Workload from "./components/workload";
import ProjectBudget from "./components/project-budget";
// import WorksNote from "./components/works-note";
import OverdueTask from "./components/overdue-task";
import ProjectBudgetBar from "./components/project-budget-bar";
// import {
//   Card,
//   CardHeader,
//   CardTitle,
//   CardContent,
// } from "../../components/ui/card";
// import UpcomingDeadline from "./components/upcoming-deadlines";
import RecentActivity from "./components/recent-activity";
import TopContributer from "./components/top-contributer";
// import ActiveTask from "./components/active-task";
import DatePickerWithRange from "../../components/misc/date-picker-with-range";
import { useEffect, useState } from "react";
import common from "../../helper/common";
import { DateminFormat, respEnum } from "../../constants/appConstant";

const Dashboard = () => {
  const [bookingSummary, setBookingSummary] = useState({});

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    await getBookingSummary();
  };

  const getBookingSummary = async (from, to) => {
    let response = await common.ApiService.getInstance().request(
      `GetBookingSummary${from ? `?startDateTime=${from}` : ""}${
        to ? `&tillDate=${to}` : ""
      }`
    );

    if (response.status == respEnum.Success) {
      let d = response?.data?.[0];
      setBookingSummary(d);
    } else {
      setBookingSummary({});
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center flex-wrap justify-between gap-4">
        <div className="text-2xl font-medium text-default-800">Dashboard</div>
        <DatePickerWithRange
          onDateChanged={(val) => {
            if (val) {
              getBookingSummary(
                val.from
                  ? common.moment(val.from).format(DateminFormat)
                  : undefined,
                val.to ? common.moment(val.to).format(DateminFormat) : undefined
              );
            }
          }}
        />
      </div>

      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-4   md:mt-0">
          <WelcomeBlock bookingSummary={bookingSummary} />
        </div>
        <div className="col-span-12 md:col-span-8">
          <div className="grid grid-cols-2 sm:grid-cols-2 2xl:grid-cols-4 gap-4">
            <ReportsCard bookingSummary={bookingSummary} />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 2xl:col-span-12 grid grid-cols-12 gap-6">
          <div className="col-span-12 md:col-span-8">
            <ProjectBudgetBar />
            {/* <ReportChart /> */}
          </div>
          <div className="col-span-12 md:col-span-4">
            <ProjectBudget bookingSummary={bookingSummary} />
            {/* <WorksNote /> */}
          </div>
          {/* <div className="col-span-12 xl:col-span-6 ">
            <Workload />
          </div>
          <div className="col-span-12  lg:col-span-6 xl:col-span-6">
            <ProjectBudgetBar />
          </div> */}
        </div>

        {/* <div className="col-span-12 xl:col-span-5">
          <ProjectBudget />
        </div> */}
      </div>

      {/* <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-6 xl:col-span-12">
          <OverdueTask />
        </div>
      </div> */}

      {/* <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-6 xl:col-span-7">
          <Card>
            <CardHeader className="border-none pt-6">
              <CardTitle>Active Task</CardTitle>
            </CardHeader>
            <CardContent className="px-3">
              <ActiveTask />
            </CardContent>
          </Card>
        </div>
        <div className="col-span-12 lg:col-span-6 xl:col-span-5">
          <UpcomingDeadline />
        </div>
      </div> */}

      <div className="grid grid-cols-12 gap-6">
        {/* <div className="col-span-12 lg:col-span-6 xl:col-span-5">
          <RecentActivity />
        </div> */}
        {/* <div className="col-span-12 lg:col-span-6 xl:col-span-7">
          <TopContributer />
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
