import React, { useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { AppSearchSectionSelect } from "../appComponents";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

// Sample resources and events
const resources = [
  { id: 1, title: "Court 1" },
  { id: 2, title: "Court 2" },
  { id: 3, title: "Court 3" },
  { id: 4, title: "Court 4" },
  { id: 5, title: "Court 5" },
];

const events = [
  {
    title: "Meeting",
    start: new Date(2024, 0, 15, 10, 0),
    end: new Date(2024, 0, 15, 12, 0),
    resourceId: 1,
  },
  {
    title: "Workshop",
    start: new Date(2024, 0, 16, 14, 0),
    end: new Date(2024, 0, 16, 16, 0),
    resourceId: 2,
  },
];

const ResourceCalendar = () => {
  const [myEvents, setEvents] = useState(events);

  const handleSelect = ({ start, end, resourceId }) => {
    const title = window.prompt("New Event name");
    if (title) {
      const newEvent = {
        title,
        start,
        end,
        resourceId,
      };
      setEvents([...myEvents, newEvent]);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-centerx">
      <div className="md:grid md:grid-cols-4 gap-3 space-y-6 mb-5">
        <div className="flex flex-col gap-3">
          <AppSearchSectionSelect
            name={"courtId"}
            label={"Court"}
            // data={courtList}
            // onChange={(e) => handleCourtChange(e.value)}
          />
        </div>
      </div>
      <div className="bg-white shadow-md p-6 rounded-lg w-full">
        <div className="h-[600px]">
          <Calendar
            localizer={localizer}
            events={myEvents}
            resources={resources}
            resourceIdAccessor="id"
            resourceTitleAccessor="title"
            views={["week", "day"]}
            defaultView="week"
            selectable
            onSelectSlot={handleSelect}
          />
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default ResourceCalendar;
