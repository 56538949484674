import { useEffect, useState } from "react";
import { loader } from "react-global-loader";
import { Icon } from "@iconify/react";
import AddLevelThreshold from "./components/addLevelThresholds";
import { Button } from "../../../components/ui/button";
import Common from "../../../helper/common";
import AdvanceTable from "../../../components/advanceTable";
import {AppSearchSelect} from "../../../components/appComponents";

const LevelThreshold = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [refId, setRefId] = useState(null);
    const [sport, setSport] = useState([]);
    const [sportId, setSportId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            getSports();
        };
        fetchData();
    }, []);

    useEffect(() => {
        getData();
    }, [sportId]);

    useEffect(() => {
        if (!open && refId) {
            setRefId();
        }
    }, [open]);

    const getSports = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "GetSportddl"
            );
            const data = [
                { id: null, name: "Select Sport" },
                ...(response?.data ?? []),
            ];

            setSport(data);
        });
    };

    const getData = async () => {
        
        setLoading(true);
        const response = await Common.ApiService.getInstance().request(
            `GetSportlevelThreshold${sportId ? `?sportId=${sportId}` : ''}`
        );
        setData(response?.data);
        setLoading(false);
    };


    const _handleDelete = async (id) => {
        const isConfirmed = await Common.SweetAlert.confirmation(
            "Are you sure you want to delete this Level Thresold?",
            "Delete Level Thresold"
        );
        if (isConfirmed) {
            let resp = await Common.ApiService.getInstance().request(
                `DeleteSportlevelThreshold?id=${id}`,
                {},
                "Delete"
            );
            Common.SweetAlert.alert(resp.message, "success");
            setTimeout(() => {
                getData(1);
            }, 500);
        }
    };

  const _addUpdateTrigger = () => (
    <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
      <Icon icon="heroicons:pencil" className="h-4 w-4" />
    </Button>
  );

    const _onEdit = async (id) => {
        setOpen(true);
        setRefId(id);
    };

    const actions = [
        {
            type: "edit",
            onClick: (val) => _onEdit(val.id),
        },
        {
            type: "delete",
            onClick: (val) => _handleDelete(val.id),
        },
    ];

  const _thead = [
    { title: "Id", type: "number", isVisible: false, key: "id" },
    { title: "Sport Id", type: "number", isVisible: false, key: "sportId" },
    { title: "Sport Name", type: "text", isVisible: true, key: "sportName" },
    { title: "Level", type: "text", isVisible: true, key: "level" },
    { title: "From Points", type: "text", isVisible: true, key: "fromPoints" },
    { title: "Till Points", type: "text", isVisible: true, key: "tillPoints" },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

    return (
        <>
            <div className="md:grid md:grid-cols-5 gap-3 space-y-6 md:space-y-0">
                <AppSearchSelect
                    value={sportId}
                    onChange={setSportId}
                    placeholder={"Select Sports"}
                    label={"Sports"}
                    data={sport}
                    className={"mb-5 col-span-1"}
                />
            </div>

            <AdvanceTable
                title={"Level Threshold"}
                columns={_thead.filter((item) => item.isVisible)}
                data={{ data: data }}
                getData={getData}
                addButton={() => (
                    <AddLevelThreshold
                        id={refId}
                        onClose={() => getData()}
                        open={open}
                        setOpen={setOpen}
                    />
                )}
                showPagination={false}
                showAddButton
                actionButtons={actions}
                updateTrigger={_addUpdateTrigger}
            />
        </>
    );
};

export default LevelThreshold;
