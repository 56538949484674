import { useEffect, useState } from "react";
import Card from "../ui/card-snippet";
import { CardContent } from "../ui/card";
import Common from "../../helper/common";
import { AppInput, AppSwitch, AppCheckbox } from "../appComponents";
import { DayOfWeekEnum } from "../../constants/appConstant";

const TimingsCard = ({ name, formikProps, data = [], showCustomButton = false }) => {
    const [timings, setTimings] = useState([]);
    const [originalData, setOriginalData] = useState([]); // To store the original data for reverting

    useEffect(() => {
        setTimings(data);
        setOriginalData(data); // Save the original data on initial load
    }, [data]);

    useEffect(() => {
        const currentFormikValue = formikProps.values[name];
        if (
            JSON.stringify(currentFormikValue) !== JSON.stringify(timings) &&
            timings
        ) {
            formikProps.setFieldValue(name, timings);
        }
    }, [timings]);

    const handleChange = (id, field, value) => {
        setTimings((prevTimings) =>
            prevTimings.map((item) =>
                item.id === id ? { ...item, [field]: value } : item
            )
        );
    };

    const handleMasterSwitch = (value) => {
        if (value) {
            const updatedTimings = timings.map((item) => ({
                ...item,
                isOn: true,
                openingTime: "00:00",
                closingTime: "23:59",
            }));
            setTimings(updatedTimings);
        } else {
            setTimings(originalData);
        }
    };

    return (
        <div className="flex flex-col gap-2">
            <Card title="Timings">
                <CardContent className="p-3">
                    <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center ml-auto">
                            <span className="ml-2 text-base font-bold text-gray-700 mr-2">
                                24-Hour On
                            </span>
                                <AppCheckbox
                                className="border-default-300"
                                onChange={(val) => handleMasterSwitch(val)}
                            />
                        </div>
                    </div>

                    {timings.map((item, index) => (
                        <div key={index} className="md:grid md:grid-cols-4 gap-2 mb-2">
                            <AppSwitch
                                value={item.isOn}
                                className="border-default-300"
                                onChange={(val) => handleChange(item.id, "isOn", val)}
                            />
                            <div className="flex items-center">
                                {Common.Utility.getKeyByValue(DayOfWeekEnum, item.dayOfweek)}
                            </div>

                            <AppInput
                                size="sm"
                                type="time"
                                value={item.openingTime}
                                onChange={(val) => handleChange(item.id, "openingTime", val)}
                                onBlur={(val) =>
                                    Common.Utility.handleBlur(val, "openingTime")
                                }
                                className="mb-1 mr-1"
                                disabled={!item.isOn}
                            />

                            <AppInput
                                size="sm"
                                type="time"
                                value={item.closingTime}
                                onChange={(val) => handleChange(item.id, "closingTime", val)}
                                onBlur={(val) =>
                                    Common.Utility.handleBlur(val, "closingTime")
                                }
                                className="mb-1 ml-1"
                                disabled={!item.isOn}
                            />
                        </div>
                    ))}
                </CardContent>
            </Card>
        </div>
    );

};

export default TimingsCard;

