import React, { useRef, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Common from "../../../helper/common";
import {
  FormikInput,
  FormikSelect,
} from "../../../components/formik-components";
import { Button } from "../../../components/ui/button";
import { Card, CardContent } from "../../../components/ui/card";
import { AppSwitch } from "../../../components/appComponents";

const formValidations = yup.object({
  courtId: yup.object().required("Court is Requied"),
});
const formInitialValues = {
  name: "",
  isDefault: true,
};

const PayMethodType = {
  cash: 1,
  bankOnline: 2,
  paymentGateway: 3,
};

export default function PayMethodAdminSetup() {
  const formikRef = useRef();

  const _onSubmit = (values) => {};

  const Heading = ({ text }) => (
    <h4 class="text-2xl font-bold dark:text-white mb-5">{text}</h4>
  );

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidations}
      innerRef={formikRef}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={_onSubmit}
    >
      {(formikProps) => (
        <Card>
          <CardContent className="p-3">
            <div className="md:grid md:grid-cols-7 mb-10 gap-2 space-y-3 md:space-y-0">
              <div className="col-span-3">
                <Heading text={"Payment Method Setup"} />
                <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-2">
                    <FormikInput
                      formikProps={formikProps}
                      name={"name"}
                      placeholder={"Enter Name"}
                      label={"Pay Method Name"}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <FormikSelect
                      name="payMethodType"
                      placeholder="Select Type"
                      label="Payment Type"
                      formikProps={formikProps}
                      data={[
                        { id: null, name: "Select Type" },
                        ...Common.Utility.enumToArray(PayMethodType),
                      ]}
                      className="mb-3 col-span-1"
                    />
                  </div>
                  <div className="flex flex-row justify-between mt-1">
                    <div className="flex flex-col gap-2">
                      <AppSwitch
                        value={formikProps.values.isDefault}
                        className="border-default-300"
                        label="Is Default"
                        onChange={(val) =>
                          formikProps.setFieldValue("isDefault", val)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}
