import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ChevronDown, Edit, Trash2, View } from "lucide-react";
// import "../../assets/nascent.css";
import { useNavigate } from "react-router-dom";
import common from "../../helper/common";
import {
  BookingStatusEnum,
  DateminFormat,
  PaymentStatusEnum,
  respEnum,
} from "../../constants/appConstant";
import AddBooking from "../clubbookings/components/addBooking";
import moment from "moment";
import { AppInput, AppSelect } from "../../components/appComponents";
import AddUpdateModal from "../../components/formComponents/addUpdateModal";
import { useQueryParams } from "../../contexts/queryParamsContext";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const BookingCalendar2 = () => {
  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [sports, setSports] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [bookingValues, setBookingValues] = useState({});
  const navigate = useNavigate();
  const { params, setParams } = useQueryParams();

  useEffect(() => {
    GetSports();
  }, []);

  useEffect(() => {
    if (selectedSport !== undefined && selectedSport !== null) {
      fetchCourtWiseBookings();
    }
  }, [selectedSport, selectedDate]);

  const GetSports = async () => {
    try {
      const response = await common.ApiService.getInstance().request(
        "GetClubSports"
      );
      const sportsData = [
        { id: null, name: "Select Sport" },
        ...(response?.data ?? []),
      ];

      setSports(sportsData);

      if (sportsData.length > 1) {
        const firstSportId = sportsData[1].id;
        setSelectedSport(firstSportId);
      }
    } catch (error) {
      console.error("Error fetching sports:", error);
    }
  };

  const fetchCourtWiseBookings = async () => {
    try {
      const formattedDate = moment(selectedDate).format("yyyy-MM-DD");
      const response = await common.ApiService.getInstance().request(
        `GetCourtWiseBookingsByDate?sportId=${selectedSport}&date=${formattedDate}`
      );

      if (response.status == respEnum.Success) {
        const calendarEvents = response.data.flatMap((court) =>
          court.bookings.map((booking) => ({
            id: booking.id,
            title: booking.playerName, //booking.bookingDetail || 'Booked',
            start: new Date(booking.startDateTime),
            end: new Date(booking.endDateTime),
            resourceId: court.courtId,
            paymentStatus: booking.paymentStatus,
            // color: "green"
          }))
        );

        const calendarResources = response.data.map((court) => ({
          id: court.courtId,
          title: court.courtName,
        }));

        setEvents(calendarEvents);
        setResources(calendarResources);
      }
    } catch (error) {
      console.error("Error fetching court bookings:", error);
    }
  };

  const handleNavigate = (newDate, view, action) => {
    setSelectedDate(newDate);
  };

  // Add new event
  const handleSelect = ({ start, end, resourceId }) => {
    setShowAdd(true);

    setBookingValues({
      courtId: resourceId,
      startDateTime: moment(new Date(start)).format("HH:mm"),
      endDateTime: moment(new Date(end)).format("HH:mm"),
    });
  };

  // Edit event
  // const handleEditEvent = (event) => {
  //   const newTitle = window.prompt("Edit Event name", event.title);
  //   if (newTitle !== null) {
  //     setEvents(
  //       events.map((evt) =>
  //         evt.id === event.id ? { ...evt, title: newTitle } : evt
  //       )
  //     );
  //     setOpenDropdownId(null);
  //   }
  // };

  // Cancel/Delete event
  // const handleCancelEvent = (event) => {
  //   const confirmCancel = window.confirm(
  //     "Are you sure you want to cancel this event?"
  //   );
  //   if (confirmCancel) {
  //     setEvents(events.filter((evt) => evt.id !== event.id));
  //     setOpenDropdownId(null);
  //   }
  // };

  const cancelledBooking = async (event) => {
    let data = {
      bookingId: +event?.id,
      status: +BookingStatusEnum.Cancelled_By_Club,
    };

    let resp = await common.ApiService.getInstance().request(
      "UpdateBookingStatus",
      data,
      "Patch"
    );
    if (resp.status == respEnum.Success) {
      common.SweetAlert.alert("Booking Cancelled Successfully", "success");
      setEvents(events.filter((evt) => evt.id !== event.id));
      setOpenDropdownId(null);
    }
  };

  const _handleOnClose = (shouldRefresh) => {
    setShowAdd(false);
    if (shouldRefresh) {
      fetchCourtWiseBookings();
    }
  };

  // Custom Event Component with Dropdown
  const CustomEvent = ({ event }) => {
    const isDropdownOpen = openDropdownId === event.id;

    return (
      <div className="relative ">
        <div className="flex items-center justify-between">
          <span className="truncate">{event.title}</span>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setOpenDropdownId(isDropdownOpen ? null : event.id);
            }}
            className="ml-2 text-gray-500 hover:text-gray-700 action-btn-dd "
          >
            <ChevronDown size={16} />
          </button>
        </div>

        {isDropdownOpen && (
          <div className="absolute z-10 right-0 mt-1 w-36 bg-white border rounded shadow-lg">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setParams({
                  ...params,
                  bookingId: event.id,
                });
                navigate(`/app/bookingDetails`);
              }}
              className="flex items-center w-full px-3 py-2 text-primary "
            >
              <View size={14} className="mr-2" /> View Details
            </button>
            <button
              onClick={async (e) => {
                e.stopPropagation();

                const isConfirmed = await common.SweetAlert.confirmation(
                  "Are you sure you want to cancel this booking? This can't be reversed!",
                  "Confirm Cancel"
                );

                if (isConfirmed) {
                  cancelledBooking(event);
                }
              }}
              className="flex items-center w-full px-3 py-2 text-red-600 hover:bg-gray-100"
            >
              <Trash2 size={14} className="mr-2" /> Cancel Booking
            </button>
          </div>
        )}
      </div>
    );
  };

  const isSlotOverlapping = (slotInfo, events) => {
    return events.some(
      (event) =>
        // Check if the resource matches
        event.resourceId === slotInfo.resourceId &&
        // Check time overlap
        slotInfo.start < event.end &&
        slotInfo.end > event.start
    );
  };

  const getEventClassName = (event) => {
    let className = "payment-icon";

    if (event.paymentStatus === PaymentStatusEnum.Paid) {
      className += " pay-paid";
    } else if (event.paymentStatus === PaymentStatusEnum.Partial_Paid) {
      className += " pay-partial";
    } else if (event.paymentStatus === PaymentStatusEnum.Un_Paid) {
      className += " pay-unpaid";
    }

    return className;
  };

  return (
    <div>
      <div className="min-h-screen flex flex-col items-centerx">
        <div className="md:grid md:grid-cols-4 grid grid-cols-2 gap-3 mb-5">
          <div className="flex flex-col gap-3">
            <AppSelect
              name={"sportId"}
              data={sports}
              value={selectedSport}
              placeholder={"Select Sport"}
              label={"Sport"}
              onChange={(val) => setSelectedSport(val)}
            />
          </div>

          <div className="flex flex-col gap-3 ">
            <AppInput
              value={moment(selectedDate).format(DateminFormat)}
              type={"date"}
              label={"Date"}
              onChange={setSelectedDate}
            />
          </div>
        </div>
        <div className="bg-white shadow-md py-3 rounded-lg w-full">
          <div className="h-100 p-0">
            <Calendar
              localizer={localizer}
              events={events}
              resources={resources}
              resourceIdAccessor="id"
              resourceTitleAccessor="title"
              views={["day"]}
              defaultView="day"
              selectable
              date={selectedDate}
              onNavigate={handleNavigate}
              onSelectSlot={(slotInfo) => {
                if (!isSlotOverlapping(slotInfo, events)) {
                  handleSelect(slotInfo);
                }
              }}
              components={{
                event: CustomEvent,
              }}
              style={{ height: "100%" }}
              eventPropGetter={(event) => ({
                className: getEventClassName(event),
              })}
            />
          </div>
        </div>
      </div>
      <AddUpdateModal
        open={showAdd}
        setOpen={setShowAdd}
        Component={(props) => (
          <AddBooking
            {...props}
            onClose={_handleOnClose}
            bookingValues={{
              ...bookingValues,
              paymentStatus: PaymentStatusEnum.Un_Paid,
              bookingDate: moment(selectedDate).format(DateminFormat),
            }}
          />
        )}
        title={"Create Booking"}
      />
    </div>
  );
};

export default BookingCalendar2;
