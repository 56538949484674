import React, { useState } from "react";
import { Card, CardContent } from "../../components/ui/card";
import { Icon } from "@iconify/react";
import { ImageConstant } from "../../constants/imageConstant";
import { Badge } from "../../components/ui/badge";
import { Star } from "lucide-react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import Utility from "../../helper/utilityHelper";
import { DayOfWeekEnum } from "../../constants/appConstant";

export default function ClubDetails() {
  const [club, setClub] = useState({
    name: "Club Padel",
    phone: "042-3431235",
    websiteUrl: "http://clubpadel.pk",
    locationImage: ImageConstant.location,
    facilities: [
      { facilityName: "WiFi" },
      { facilityName: "Special Access" },
      { facilityName: "Lockers" },
      { facilityName: "Online Streaming" },
    ],
    timings: Utility.enumToArray(DayOfWeekEnum),
    address:
      "4th Floor at, Gold crest mall sector DD phase 4 Dha lahore, 456DD Phase IV Sector DD DHA Phase 3, Lahore, Punjab 54000",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
  });

  const Heading = ({ text }) => (
    <h4 class="text-2xl font-bold dark:text-white mb-5">{text}</h4>
  );

  const LeftIcon = ({ icon, className = "w-8 h-8" }) => (
    <Icon icon={icon} className={className} />
  );

  return (
    <Card>
      <CardContent className="p-3">
        <div className="md:grid md:grid-cols-7 mb-10 gap-2 space-y-3 md:space-y-0">
          <div className="col-span-3 pr-5">
            {/* <Heading text={"Club Details"} /> */}
            <div className="md:grid md:grid-cols-1 gap-2 space-y-3 md:space-y-0">
              <h6 class="text-2xl font-bold dark:text-white">{club.name}</h6>
              <div className="flex flex-row gap-2 space-y-3 md:space-y-0 items-center">
                <LeftIcon icon={"material-symbols:share-location-rounded"} />
                <span>{club.address}</span>
              </div>
              <div className="flex flex-row gap-2 space-y-3 md:space-y-0 items-center">
                <LeftIcon
                  icon={"material-symbols:phone-enabled-outline"}
                  className="w-5 h-5"
                />
                <span>{club.phone}</span>
              </div>
              <div className="flex flex-row gap-2 space-y-3 md:space-y-0 items-center">
                <LeftIcon
                  icon={"material-symbols:public"}
                  className="w-5 h-5"
                />
                <a
                  href={club.websiteUrl}
                  target="blank"
                  className="text-blue-600"
                >
                  {club.websiteUrl}
                </a>
              </div>
              <div className="flex mb-3">
                <img className="rounded-xl mt-7" src={club.locationImage} />
              </div>
              <div className="flex flex-row gap-2 space-y-3 md:space-y-0 items-center mt-5">
                {club.facilities.map((item) => (
                  <Badge color="secondary">
                    <Star className=" mr-1 h-3 w-3" />
                    {item.facilityName}
                  </Badge>
                ))}
              </div>
              <div className="flex flex-col w-full ">
                {club.timings.map((item) => (
                  <div className="flex flex-row w-full justify-between mt-2">
                    <span className="font-semibold">{item.name}</span>
                    <span>09:00 AM - 08:00 PM</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-span-4 border-l-2 pl-5">
            <Carousel
              plugins={[
                Autoplay({
                  delay: 2000,
                }),
              ]}
              opts={{
                align: "start",
              }}
              orientation="vertical"
              className="w-full max-w-1xl mx-auto my-12"
            >
              <CarouselContent className="-mt-1 h-[500px]">
                {Array.from({ length: 5 }).map((_, index) => (
                  <CarouselItem key={index} className="pt-1 md:basis-1/2">
                    <div className="p-1">
                      <div className="flex items-center justify-center h-[250px]">
                        <img
                          className="w-full h-full object-cover rounded-lg"
                          src={ImageConstant.manWithLaptop}
                          alt="image"
                        />
                      </div>
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselPrevious />
              <CarouselNext />
            </Carousel>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
