import { useEffect, useRef } from "react";
// import animationData from "../../../assets/json/lotties/noData.json";
import animationData from "../../../assets/json/lotties/not-found.json";
// import Image from "next/image";
// import lightImage from "@/public/images/error/light-403.png";
// import lightImage from "../../../pub";
// import darkImage from "@/public/images/error/dark-403.png";
// import { Button } from "@/components/ui/button";
// import Link from "next/link";
// import { useTheme } from "next-themes";

const Error404 = () => {
  let animationRef = useRef(null);

  const getLottie = async () => {
    const lot = await import("lottie-web");

    lot.default.loadAnimation({
      loop: true,
      autoplay: true,
      animationData: animationData,
      container: animationRef.current,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    });
  };
  useEffect(() => {
    getLottie();
  }, []);

  //   const { theme } = useTheme();
  return (
    <div className="min-h-screen  overflow-y-auto flex justify-center items-center p-10">
      <div className="w-full flex flex-col items-center">
        <div className="max-w-[542px]">
          {/* <Image
            src={theme === "dark" ? darkImage : lightImage}
            alt="error image"
            className="w-full h-full object-cover"
          /> */}
          <div style={{ marginTop: "-150px" }} ref={animationRef}></div>
        </div>
        <div className="text-center">
          <div className="text-2xl md:text-4xl lg:text-5xl font-semibold text-default-900">
            Ops! Access Denied
          </div>
          <div className="mt-3 text-default-600 text-sm md:text-base">
            The page you are looking for might have been removed had <br /> its
            name changed or is temporarily unavailable.
          </div>
          {/* <Button asChild className="mt-9  md:min-w-[300px]" size="lg">
            <Link href="/dashboard">Go to Homepage</Link>
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default Error404;
