import {
  ChevronsLeft,
  ChevronRight,
  ChevronsRight,
  ChevronLeft,
} from "lucide-react";
import { Button } from "../../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";

export default function AdvancedTablePagination({
  currentPage = 1,
  totalPages = 10,
  onPageChanged = () => {},
  displayCount,
}) {
  // Ensure displayCount defaults to at least 15 if it's less than that
  const displayCountValue = displayCount < 15 ? 15 : displayCount;

  return (
    <div>
      {/* Display "No Data Found" if there are no pages */}
      {totalPages === 0 ? (
        <div className="text-center text-muted-foreground p-4">
          No Data Found
        </div>
      ) : (
        <div className="flex items-center flex-wrap gap-2 justify-between px-4 py-3">
          <div className="flex items-center space-x-2">
            {/* <p className="text-sm font-medium text-muted-foreground whitespace-nowrap">
            
            </p> */}
            <Select
              value={`${displayCountValue}`}
              onValueChange={(value) => onPageChanged(1, "", parseInt(value))}
            >
              <SelectTrigger className="h-8 w-[70px]">
                <SelectValue placeholder={15} />
              </SelectTrigger>
              <SelectContent side="top">
                {[15, 30, 50, 100].map((pageSize) => (
                  <SelectItem key={pageSize} value={`${pageSize}`}>
                    {pageSize}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex">
            <div className="flex w-[100px] items-center justify-center text-sm font-medium text-muted-foreground">
              {`Page ${currentPage} of ${totalPages}`}
            </div>
            <div className="flex items-center space-x-2">
              <Button
                variant="outline"
                className="hidden h-8 w-8 p-0 lg:flex"
                onClick={() => onPageChanged(1, "", displayCountValue)}
                disabled={currentPage <= 1}
              >
                <span className="sr-only">Go to first page</span>
                <ChevronsLeft className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="h-8 w-8 p-0"
                onClick={() =>
                  onPageChanged(currentPage - 1, "", displayCountValue)
                }
                disabled={currentPage <= 1}
              >
                <span className="sr-only">Go to previous page</span>
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="h-8 w-8 p-0"
                onClick={() =>
                  onPageChanged(currentPage + 1, "", displayCountValue)
                }
                disabled={currentPage == totalPages}
              >
                <span className="sr-only">Go to next page</span>
                <ChevronRight className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="hidden h-8 w-8 p-0 lg:flex"
                onClick={() => onPageChanged(totalPages, "", displayCountValue)}
                disabled={currentPage == totalPages}
              >
                <span className="sr-only">Go to last page</span>
                <ChevronsRight className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
