import React, { useState } from "react";
import { useThemeStore, useSidebar } from "../../../store";
import MobileFooter from "./mobile-footer";
import FooterLayout from "./footer-layout";
import { cn } from "../../../helper/utilityHelper";
import { useMediaQuery, useMounted } from "../../../hooks";

// import ThemeCustomize from "../../components/partials/customizer/theme-customizer";
import ThemeCustomize from "../customizer/theme-customizer";
import { useNavigate } from "react-router-dom";
import {
  LayoutDashboard,
  CalendarCheck,
  PlusCircle,
  Users,
  CreditCard,
  ChevronsUp,
  ChevronsDown,
} from "lucide-react";
import { useMenu } from "../../../contexts/menuContext";

const Footer = ({ handleOpenSearch }) => {
  const { collapsed, sidebarType } = useSidebar();
  const { layout, footerType } = useThemeStore();
  const mounted = useMounted();
  const isMobile = useMediaQuery("(min-width: 768px)");

  if (!mounted) {
    return null;
  }
  if (!isMobile && sidebarType === "module") {
    return <MobileFooter handleOpenSearch={handleOpenSearch} />;
  }

  if (footerType === "hidden") {
    return null;
  }

  if (layout === "semibox") {
    return (
      <div className="xl:mx-20 mx-6">
        <FooterLayout
          className={cn(" rounded-md border", {
            "xl:ml-[72px]": collapsed,
            "xl:ml-[272px]": !collapsed,
            "sticky bottom-0": footerType === "sticky",
          })}
        >
          <FooterContent />
        </FooterLayout>
      </div>
    );
  }
  if (sidebarType !== "module" && layout !== "horizontal") {
    return (
      <FooterLayout
        className={cn("", {
          "xl:ml-[248px]": !collapsed,
          "xl:ml-[72px]": collapsed,
          "sticky bottom-0": footerType === "sticky",
        })}
      >
        <FooterContent />
      </FooterLayout>
    );
  }

  if (layout === "horizontal") {
    return (
      <FooterLayout
        className={cn("", {
          "sticky bottom-0": footerType === "sticky",
        })}
      >
        <FooterContent />
      </FooterLayout>
    );
  }

  return (
    <FooterLayout
      className={cn("", {
        "xl:ml-[300px]": !collapsed,
        "xl:ml-[72px]": collapsed,
        "sticky bottom-0": footerType === "sticky",
      })}
    >
      <FooterContent />
    </FooterLayout>
  );
};

export default Footer;
const FooterContent = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("dashboard");
  const { isMenuVisible, setIsMenuVisible, toggleMenu } = useMenu();

  const navItems = [
    {
      name: "dashboard",
      icon: LayoutDashboard,
      label: "Dashboard",
      route: "/app/dashboard",
    },
    {
      name: "bookings",
      icon: CalendarCheck,
      label: "Bookings",
      route: "/app/clubbookings",
    },
    {
      name: "plus",
      icon: PlusCircle,
      label: "Add",
      route: "/app/bookingcalendar",
    },
    {
      name: "customers",
      icon: Users,
      label: "Customers",
      route: "/app/clubcustomers",
    },
    {
      name: "payments",
      icon: CreditCard,
      label: "Payments",
      route: "/app/transactions?status=2",
    },
  ];

  const handleNavigation = (item) => {
    setActiveTab(item.name);
    navigate(item.route);
  };

  const toggleMenuVisibility = () => {
    toggleMenu();
  };

  return (
    <>
      {/* Toggle Button */}
      <div className="fixed bottom-0 right-0 z-[100]">
        <button
          onClick={toggleMenuVisibility}
          className="bg-white text-[#6b7280] p-1  transition-all"
        >
          {isMenuVisible ? (
            <ChevronsDown size={24} />
          ) : (
            <ChevronsUp size={24} />
          )}
        </button>
      </div>

      {/* Mobile Footer Menu */}
      <div
        className={` mobile-footer
          fixed bottom-0 left-0 right-0 z-50 transition-all duration-300 ease-in-out
          ${isMenuVisible ? "translate-y-0" : "translate-y-full"}
        `}
      >
        <div className="mx-4 bg-white shadow-2xl rounded-t-2xl">
          <div className="flex justify-between items-center px-4 py-3">
            {navItems.map((item) => (
              <button
                key={item.name}
                onClick={() => handleNavigation(item)}
                className={`
                  flex flex-col items-center justify-center 
                  transition-all duration-300 ease-in-out
                  ${
                    item.name === "plus"
                      ? "scale-125 bg-primary-500 text-white rounded-full p-2"
                      : ""
                  }
                  ${
                    activeTab === item.name
                      ? "text-primary-600"
                      : "text-gray-500"
                  }
                  hover:text-primary-600 hover:scale-110  
                `}
              >
                {item.name === "plus" && <ThemeCustomize />}
                <item.icon
                  size={item.name === "plus" ? 32 : 24}
                  strokeWidth={activeTab === item.name ? 2.5 : 1.5}
                />
                {item.name !== "plus" && (
                  <span className="text-xs mt-1">{item.label}</span>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="block md:flex md:justify-center text-muted-foreground">
        <p className="sm:mb-0 text-xs md:text-sm">
          Copyright © {new Date().getFullYear()}{" "}
        </p>
        <p className="mb-0 text-xs md:text-sm">
          <a
            href="https://nascentinnovations.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            &nbsp;Nascent Innovations
          </a>
        </p>
      </div>
    </>
  );
};
