import React from "react";

const CustomIcon = ({ className }) => (
  <svg
    height="800px"
    width="800px"
    viewBox="0 0 40.933 40.933"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="#dfff4f" // Allows color to be controlled via CSS or Tailwind classes
  >
    <g>
      <g>
        <g>
          <path d="M18.546,0.079C13.967,0.506,9.497,2.477,5.984,5.988c-3.513,3.51-5.482,7.98-5.908,12.565 c5.028,0.583,10.374,3.153,14.763,7.542c4.391,4.39,6.964,9.74,7.542,14.764c4.592-0.426,9.051-2.396,12.567-5.905 c3.512-3.509,5.479-7.981,5.903-12.565c-5.016-0.582-10.371-3.152-14.76-7.542C21.705,10.458,19.132,5.107,18.546,0.079z" />
          <path d="M40.933,19.85c-0.149-5.035-2.141-10.023-5.979-13.866C31.114,2.141,26.12,0.156,21.085,0 c0.566,4.397,2.901,9.122,6.815,13.037C31.814,16.952,36.533,19.283,40.933,19.85z" />
          <path d="M0.001,21.09c0.148,5.033,2.141,10.022,5.983,13.863c3.839,3.839,8.827,5.834,13.861,5.98 c-0.566-4.391-2.898-9.113-6.814-13.028C9.119,23.992,4.395,21.66,0.001,21.09z" />
        </g>
      </g>
    </g>
  </svg>
);

export default CustomIcon;
