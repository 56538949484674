import { Label } from "../ui/label";
import Select, { components } from "react-select";
import "react-select-search/style.css";

export default function AppSearchSectionSelect({
  value,
  onChange = () => {},
  placeholder,
  data = [],
  label,
  labelButton,
  style,
  className,
  disabled = false,
}) {
  const options = [
    // { name: "Swedish", value: "sv" },
    // { name: "English", value: "en" },
    {
      type: "group",
      name: "Group name",
      items: [{ name: "Spanish", value: "es" }],
    },
  ];

  const OptionComponent = ({ data, ...props }) => {
    //const Icon = data.icon;

    return (
      <components.Option {...props}>
        <div className="flex items-center gap-2">{data.label}</div>
      </components.Option>
    );
  };

  return (
    <div className={className}>
      <div className="flex justify-between">
        {label && (
          <Label className="mb-2" htmlFor="inputId">
            {label}
          </Label>
        )}
        {labelButton && labelButton()}
      </div>

      <Select
        options={data}
        className="react-select"
        classNamePrefix="select"
        value={value}
        onChange={(val) => {
          global.log(val);
          onChange(val);
        }}
        components={{
          Option: OptionComponent,
        }}
        styles={style}
        isDisabled={disabled}
      />

      {/* <SelectSearch
        options={data}

        search
        value={value}
        onChange={onChange}
        name="language"
        // className={"container"}
        placeholder={placeholder}
      /> */}
    </div>
  );
}
